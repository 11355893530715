@use '../../../../node_modules/bootstrap/scss/bootstrap' as *;
@use "variables" as *;
@use "mixins" as *;
@use "base64" as *;

@import "../portfolio/scss/evmc";
@import "../portfolio/scss/canary";
@import "../portfolio/scss/shadowcraft";
@import "../portfolio/scss/newera";
@import "../portfolio/scss/mythos";

.portfolio-content {
    .featurette {
        position: relative;
        text-align: center;

        .hover {
            background-color: rgba(0, 0, 0, 0.5);
            transition: .5s ease;
            position: absolute;
            opacity: 0;
            @extend .w-100;
            @extend .h-100;

            .about {
                color: white;
                text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
            }

            .feature-button {
                &#evil-btn {
                    background-color: $evilred !important;
                    border: 0;
                }

                &#canary-btn {
                    background-color: $canaryorange !important;
                    border: 0;
                }

                &#scraft-btn {
                    background-color: $craftblue !important;
                    border: 0;
                }

                &#newera-btn {
                    background-color: white !important;
                    border: 0;
                    color: $newerablue;
                }

                &#mythos-btn {
                    background-color: $mythoslightgrey !important;
                    border: 0;
                }

                &#thclabz-btn {
                    background-color: $thcgreen !important;
                    border: 0;
                    color: black;
                }

                &#darknet-btn {
                    background-color: $darknetblue !important;
                    border: 0;
                }
&#assetdash-btn {
    background-color: $assetdashgreen !important;
    border: 0;
}
            }
        }

        &:hover {
            .hover {
                opacity: 1;
            }
        }

        &#evmc {
            @extend %bg-evmc;

            .portfolio-logo {
                position: absolute;
                width: 40%;
                top: 50%;
                left: 35%;
                transform: translate(-35%, -50%);
            }

            .portfolio-icon {
                margin: {
                    top: 50px;
                    bottom: 50px;
                    right: 25%;
                }
            }

            @media (max-width: 512px) {
                height: 250px;

                .portfolio-logo {
                    z-index: 2;
                    width: 95%;
                    left: 35%;
                }

                .portfolio-icon {
                    height: 75%;

                    margin: {
                        top: 5%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }

                    z-index: 1;
                }
            }
        }

        &#canary {
            @extend %bg-canary;

            .portfolio-logo {
                position: absolute;
                width: 30%;
                top: 50%;
                right: 25%;
                transform: translateY(-50%);
            }

            .portfolio-icon {
                height: 100%;

                margin: {
                    top: 50px;
                    bottom: 50px;
                    left: 50%;
                }
            }

            @media (max-width: 512px) {
                height: 250px;
                padding: 25px 0 0 0;
                overflow: hidden;

                .portfolio-logo {
                    z-index: 2;
                    width: 75%;
                    right: 10%;
                    top: 60%;
                }

                .portfolio-icon {
                    height: 90%;
                    margin: 0;
                    z-index: 1;
                }
            }
        }

        &#scraft {
            @extend %bg-scraft;

            .portfolio-logo {
                position: absolute;
                width: 40%;
                top: 50%;
                left: 35%;
                transform: translate(-35%, -50%);
            }

            .portfolio-icon {
                margin: {
                    top: 50px;
                    bottom: 50px;
                    right: 25%;
                }
            }

            @media (max-width: 512px) {
                height: 250px;
                background-position-y: -50px;

                .portfolio-logo {
                    z-index: 2;
                    width: 75%;
                    left: 40%;
                }

                .portfolio-icon {
                    height: 75%;

                    margin: {
                        top: 5%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }

                    z-index: 1;
                }
            }
        }

        &#newera {
            background-color: $newerablue;
            height: 356px;

            .portfolio-logo {
                @extend .center-content;
            }

            @media (max-width: 512px) {
                height: 250px;

                .portfolio-logo {
                    z-index: 2;
                    height: 110px;
                }
            }
        }

        &#mythos {
            background-color: $mythosgrey;
            height: 356px;

            .portfolio-logo {
                @extend .center-content;
                height: 128px;
            }

            @media (max-width: 512px) {
                height: 250px;

                .portfolio-logo {
                    z-index: 2;
                    height: 82px;
                }
            }
        }

        &#darknet {
            background-color: $darknetgrey;
            height: 356px;

            .portfolio-logo {
                @extend .center-content;
                height: 176px;
            }

            @media (max-width: 512px) {
                height: 250px;

                .portfolio-logo {
                    z-index: 2;
                    height: 82px;
                }
            }
        }

        &#thclabz {
            background-color: black;
            height: 356px;

            .portfolio-logo {
                @extend .center-content;
                height: 256px;
            }

            @media (max-width: 512px) {
                height: 250px;

                .portfolio-logo {
                    z-index: 2;
                    height: 128px;
                }
            }
        }
&#assetdash {
    background-color: rgb(28, 30, 41);
    height: 356px;

    .portfolio-logo {
        position: absolute;
        width: 20%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .portfolio-icon {
        position: absolute;
        width: 5%;
        top: 50%;
        left: 35%;
        transform: translate(-35%, -50%);
    }

    @media (max-width: 512px) {
        height: 250px;
        background-position-y: -50px;

        .portfolio-logo {
            z-index: 2;
            width: 75%;
            left: 40%;
        }

        .portfolio-icon {
            height: 75%;

            margin: {
                top: 5%;
                bottom: 0;
                left: 0;
                right: 0;
            }

            z-index: 1;
        }
    }
}
    }
}