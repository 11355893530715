@use '../../../../node_modules/bootstrap/scss/bootstrap';

@use "variables" as *;
@use "base64";
@use "mixins";
@use "media";
@use "common";
@use "fonts";

@import "portfolio";

html,
body {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

/* width */
::-webkit-scrollbar {
    padding-left: 0;
    width: 10px;
    margin-right: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $secondary;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: lighten($fallenblue, 5%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: lighten($fallenblue, 10%);
}

header {
    .primary-navbar {
        background: linear-gradient(white 50%, $fallenblue 50%);
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
        padding-top: 5px;
        padding-bottom: 70px;

        a {
            text-decoration: none;
        }

        .brand-text {
            position: absolute;
            left: 5px;
            top: 5px;
        }

        .navbar-brand {
            color: #333333;
        }
    }

    .fallen-logo {
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, 0);
        border-radius: 4rem;
        width: 96px;
        height: 96px;
        padding: 15px;
        z-index: 10;

        img {
            height: 64px;
            width: 64px;
        }
    }
}

.content {
    margin-top: 100px;
    margin-bottom: 150px;

    .header-img {
        height: 384px;
        background: url('../../assets/images/header_img.jpg');
        background-size: cover;
        background-position: 0 -100px;
        background-repeat: no-repeat;
        background-attachment: fixed;
        position: relative;

        .backdrop {
            background-color: rgba(0, 0, 0, 0.25);
            position: absolute;
            width: 100%;
            height: 100%;

            .header-text {
                text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
            }
        }
    }

    .banner {
        height: 72px;
    }

    .services {
        min-height: 50vh;
        color: #333;
    }

    .about-page {
        a {
            color: $primary;
            text-decoration: none;

            &:hover {
                color: lighten($primary, 20%);
            }
        }
    }
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 165px;
}