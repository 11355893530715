@use '../../scss/base64' as *;
@use '../../scss/variables' as *;
@use '../../scss/common' as *;
@use '../../scss/mixins' as *;

.mythos-content {
    background-color: $mythosgrey;

    .portfolio-splash {
        position: relative;
        display: block;
        height: 400px;
        width: 100%;

        img {
            @include center;

            &.mythos-logo {
                z-index: 10;
                width: 30%;
            }

            @media (max-width: 512px) {
                &.mythos-logo {
                    width: 95%;
                }
            }
        }
    }
}