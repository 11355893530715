@mixin fm-link {
    a {
        color: $fallenblue !important;
        text-decoration: none;

        &:hover,
        &:active {
            color: lighten($fallenblue, 20%) !important;
        }
    }
}

@mixin light-link {
    a {
        color: white;
        text-decoration: none;

        &:hover,
        &:active {
            transition: color .15s ease-in-out;
            color: darkgray;
        }
    }
}

@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}