@use '../../../../node_modules/bootstrap/scss/bootstrap.scss' as *;
@use 'variables' as *;
@use 'base64' as *;

.no-gutter {
    margin: {
        left: -24px;
        right: -24px;
    }
}

.text-blue {
    color: $fallenblue;
}

.text-white {
    @extend .text-light;
}

.text-muted-light {
    color: lighten(#6c757d, 25%) !important
}

.text-muted-dark {
    color: #6c757d !important;
}

.filler-1 {
    height: 100px;
    display: block;
}

.filler-2 {
    height: 200px;
    display: block;
}

.filler-3 {
    height: 300px;
    display: block;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.ws-1 {
    word-spacing: 0.25rem;
}

.ws-2 {
    word-spacing: 0.5rem;
}

.ws-3 {
    word-spacing: 0.75rem;
}

.bs-1 {
    box-shadow: 1px 1px 1px darkgrey;
}

.bs-2 {
    box-shadow: 3px 3px 3px darkgrey;
}

.bs-3 {
    box-shadow: 5px 5px 5px darkgrey;
}

.bs-i-1 {
    box-shadow: 1px 1px 1px inset darkgrey;
}

.bs-i-2 {
    box-shadow: 3px 3px 3px inset darkgrey;
}

.bs-i-3 {
    box-shadow: 5px 5px 5px inset darkgrey;
}

.ts-1 {
    text-shadow: 1px 1px lightgrey;
}

.ts-2 {
    text-shadow: 2px 2px lightgrey;
}

.br-1 {
    border-radius: 1rem;
}

.br-2 {
    border-radius: 2rem;
}

.br-3 {
    border-radius: 3rem;
}

.display-7 {
    font-size: calc(1.115rem + 1.5vw);
    font-weight: 300;
    line-height: 1.1;
}

.display-8 {
    font-size: calc(1rem + 1.5vw);
    font-weight: 300;
    line-height: 1;
}

.display-9 {
    font-size: calc(0.75rem + 1.5vw);
    font-weight: 300;
    line-height: 0.75;
}

.display-10 {
    font-size: calc(0.50rem + 1vw);
    font-weight: 300;
    line-height: 1;
}

.bg-blue {
    background-color: $fallenblue !important;
}

.bg-black {
    background-color: black;
}

.btn-primary,
.list-group-item.active {
    @extend .bg-blue;
    border-color: $fallenblue;
}

.center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}